* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

html {
  font-size: 16px;
  font-family: sans-serif;
  line-height: 1.2;

  color: #000000;
  background-color: #FFFFFF;
}

.container {
  padding: 16px 0;

  display: grid;
  grid-template-columns: 1fr 1fr 10px 740px 10px 1fr 1fr;
}

@media (max-width: 800px) {
  .container {
    grid-template-columns: 10px 0 10px calc(100vw - 40px) 10px 0 10px;
  }
}

.container > * {
  grid-column: 4;
}

h1,
h2,
h3,
h4 {
  margin: 1rem 0 1rem 0;
}

h1 {
  font-size: 1.4rem;
}

h2 {
  font-size: 1.25rem;
}

h3 {
  font-size: 1.1rem;
}

h4 {
  font-size: 1rem;
}

p, .container-block {
  margin: 0.5rem 0 0 0;
}

textarea {
  width: 100%;
}

.button {
  padding: 0.5rem;
  color: #000000;
  background-color: #FDA4AF;
  text-decoration: none;
  border: none;
  cursor: pointer;
}

.button:hover {
  text-decoration: underline;
}

.error {
  padding: 0.5rem;
  background-color: #FDA4AF;
}
